body {
  margin: 0;
  padding: 0;
  min-height: 100vh; /* Ensure the body takes up at least the full viewport height */
  background-image: url("bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.App {
  text-align: center;
  max-width: 400px;
  margin: 100px auto; /* Add margin to move the .App element down */
  padding: 20px;
  border: 2px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
  margin-top: -5px;
  text-align: center;
}

form {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr auto;
  align-items: center;
  width: 100%; /* Ensures the grid takes the full width */
}

input[type="text"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  padding: 10px 20px;
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #0056b3;
}

.error-message {
  color: #ff0000;
  font-size: 14px;
  margin-top: 10px;
}

.copy-icon {
  margin-left: 10px;
  cursor: pointer;
  vertical-align: middle; /* Align it with the text */
  color: #007BFF; /* Color for the icon (you can change as needed) */
}

.copy-icon:hover {
  color: #0056b3;
}

