.footer {
    background-color: grey;
    padding: 10px 0;
    position: fixed;
    left:0;
    bottom: 0;
    width: 100%;
    text-align: center;
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .footer p {
    margin: 0;
    color: white;
  }
  
  .social-icons {
    margin-top: 10px;
  }
  
  .social-icons a {
    margin: 0 10px;
    color: white;
    font-size: 20px; /* adjust size as needed */
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .social-icons a:hover {
    color: #ddd; /* adjust color for hover effect as needed */
  }
  